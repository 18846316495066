import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import log from 'loglevel';
import template from './prop-phase-stats-table.componet.html';
import {
  ChallengePhase,
  Customer,
  TradingAccount,
  Challenge,
} from '@proftit/crm.api.models.entities';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { IScope } from 'angular';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { CalculationMethod } from '@proftit/crm.api.models.entities/src';
import popupService from '~/source/common/components/modal/popup.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import CustomersService from '~/source/contact/common/services/customers';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import CustomerChallengePhaseSocketService from '~/source/contact/contact-page/prop-account/customer-challenge-phase-socket.service';
import { TokensService } from '~/source/auth/services/tokens';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { BrandsService } from '~/source/management/brand/services/brands';
import {
  buildMt4AccountPnlChannel,
  Mt4AccountPnlSocketService,
} from '~/source/common/services/mt4-account-pnl-socket.service';

const styles = require('./prop-phase-stats-table.componet.scss');

const ACCOUNT_PNL_UPDATE_THROTTLE = 5000;

type PhaseStats = {
  id: number;
  name: string;
  startedAt: string;
  startingBalance: number;
  status: string;
  profitTarget?: number;
  maxLoss?: number;
  maxDailyLoss?: number;
  minTradingPeriod: string;
  tradingPeriod: string;
  isFunded: boolean;
  profitBroker?: number;
  profitTrader?: number;
  prizeValue?: number;
  isSplitPrize?: boolean;
  tradingAccount?: IElementRestNg<TradingAccount>;
  openPnl: number;
  closePnl: number;
};

type ChangePhaseFields<T, R> = Omit<T, keyof R> & R;
type ReceivedChallengePhase = ChangePhaseFields<
  ChallengePhase,
  {
    staringBalance: number;
    fields: {
      profitTarget: { value: number };
      maxLoss: { value: number };
      maxDailyLoss: { value: number };
      minTradingPeriod: number;
      tradingPeriod: number;
      prizeAmount: {
        value: number;
        calculationType: CalculationMethod;
      };
      profitSplit: {
        value: number;
        calculationType: CalculationMethod;
      };
    };
    currencyName: string;
    isSplitPrize: boolean;
    tradingAccount: IElementRestNg<TradingAccount>;
  }
>;

type HistoryLogRow = {
  createdAt: string;
  brokerShare: number;
  traderShare: number;
  profit: number;
  withdrawableBalance: number;
  traderWithdrawal: number;
  brokerFee: number;
  previousBalance: number;
  newBalance: number;
};

export class PropPhaseStatsTableController {
  styles = styles;
  challenge: Challenge;
  account: IElementRestNg<TradingAccount>;
  customer: IElementRestNg<Customer>;
  lifecycles = observeComponentLifecycles(this);
  user$ = this.streamUser();
  phaseStats$ = this.streamStats();
  isHistoryLog: boolean = false;
  historyLog: HistoryLogRow[] = [];
  phaseChannels: rx.Subscription[] = [];
  prfCurrentPlatformSession$ = new rx.BehaviorSubject<
    CurrentPlatformSessionStoreServiceDirectiveController
  >(null);

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly popupService: popupService,
    readonly customersService: () => CustomersService,
    readonly tokensService: TokensService,
    readonly customerChallengePhaseSocketService: CustomerChallengePhaseSocketService,
    readonly brandsService: () => BrandsService,
    readonly mt4AccountPnlSocketService: () => Mt4AccountPnlSocketService,
  ) {
    useStreams(
      [this.user$, this.phaseStats$, this.streamProfitShareHistoryLog()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        this.streamLoginToPlatformSession(),
        this.streamPlatformSocketAccountChangesForMt4(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {
    this.phaseChannels.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  $onChanges() {}

  streamLoginToPlatformSession() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.prfCurrentPlatformSession$.pipe(
            rx.filter((service) => !_.isNil(service)),
          ),
          rx.obs.of(this.customer),
        ),
      rx.switchMap(([sessionService, customer]) => {
        return this.brandsService().getBrandPlatformPerPlatform(
          customer.brand.id,
          customer.brand.platformType.code.toUpperCase(),
        );
      }),
      rx.withLatestFrom(this.prfCurrentPlatformSession$),
      rx.switchMap(([brandPlatform, sessionService]) => {
        if (_.isNil(brandPlatform)) {
          return rx.obs.NEVER;
        }
        return sessionService.login(brandPlatform);
      }),
    )(null);
  }

  streamPlatformSocketAccountChangesForMt4() {
    return rx.obs
      .combineLatest([
        this.prfCurrentPlatformSession$.pipe(
          rx.filter((service) => !_.isNil(service)),
        ),
        this.phaseStats$,
      ])
      .pipe(
        rx.map(([service, phaseStats]) => {
          const filteredPhases = (phaseStats as PhaseStats[]).filter(
            (phase) => phase.isFunded && phase.tradingAccount !== null,
          );
          return [service, filteredPhases] as [typeof service, PhaseStats[]];
        }),
        rx.filter(([service, filteredPhases]) => {
          return filteredPhases.length > 0;
        }),
        rx.switchMap(([service, filteredPhases]) =>
          service.sessionS.stream$.pipe(
            rx.map((sessionInfo) => ({
              sessionInfo,
              phaseStats: filteredPhases,
            })),
          ),
        ),
        rx.switchMap(({ sessionInfo, phaseStats }) => {
          const serviceInstance = this.mt4AccountPnlSocketService();
          serviceInstance.setToken(sessionInfo.session.token);
          serviceInstance.setStreamerUrl(sessionInfo.session.streamerUrl);
          const channel = buildMt4AccountPnlChannel(
            phaseStats[0].tradingAccount.syncRemoteId,
          );
          return observeChannel(serviceInstance, channel).pipe(
            rx.map((itemUpdates: any) => {
              phaseStats[0].openPnl = itemUpdates.pnl;
              phaseStats[0].closePnl = -itemUpdates.closed_profit; //minus pnl cause we are from the broker perspective
              return phaseStats;
            }),
          );
        }),
        rx.throttleTime(
          ACCOUNT_PNL_UPDATE_THROTTLE,
          rx.scheduler.asyncScheduler,
          {
            leading: true,
            trailing: true,
          },
        ),
        rx.catchError((err) => {
          log.error('Error in observing MT4 account updates', err);
          return rx.obs.EMPTY;
        }),
      );
  }

  streamUser() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.filter((isInit) => isInit),
      rx.map(() => this.tokensService.getCachedUser()),
      shareReplayRefOne(),
    )(null);
  }

  streamStats() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      rx.map(() => this.challenge.phases),
      rx.filter((phases) => !!phases),
      rx.switchMap(() => this.fetchPhaseStats()),
      rx.tap(() => this.subscribeToPhaseChannels()),
      shareReplayRefOne(),
    )(null);
  }

  subscribeToPhaseChannels() {
    this.user$
      .pipe(
        rx.switchMap((user) => {
          return rx.obs.from(this.challenge.phases).pipe(
            rx.map((phase) => {
              const crmChannelName = `user.${user.id}.CustomerChallengePhase.${phase.id}`;
              const channel$ = observeChannel<any>(
                this.customerChallengePhaseSocketService,
                crmChannelName,
              );

              return { phase, channel$ };
            }),
          );
        }),
        rx.tap(({ phase, channel$ }) => {
          const unsubscribe = channel$
            .pipe(
              rx.withLatestFrom(this.phaseStats$),
              rx.tap(([data, phaseStats]) => {
                const targetPhaseStatIndex = phaseStats.findIndex(
                  (stat) => stat.id === data.id,
                );
                if (targetPhaseStatIndex !== -1) {
                  phaseStats[targetPhaseStatIndex] = {
                    ...this.parsePhaseStats(data),
                    openPnl: phaseStats[targetPhaseStatIndex].openPnl,
                    closePnl: phaseStats[targetPhaseStatIndex].closePnl,
                  };
                }
              }),
            )
            .subscribe();
          this.phaseChannels.push(unsubscribe);
        }),
      )
      .subscribe();
  }

  fetchPhaseStats(): Promise<PhaseStats[]> {
    const stats = this.challenge.phases.map((phase) =>
      this.parsePhaseStats(phase),
    );
    return Promise.resolve(stats);
  }

  parsePhaseStats(phase: ReceivedChallengePhase): PhaseStats {
    const { fields } = phase;
    const traderSplitPerc =
      fields.profitSplit?.calculationType === 'percentage'
        ? (fields.profitSplit.value ?? 0) / 100
        : 0;
    const fixedTrader =
      fields.profitSplit?.calculationType === 'fixed'
        ? fields.profitSplit.value ?? 0
        : 0;
    return {
      id: phase.id,
      name: phase.name,
      startedAt: phase.startedAt,
      status: phase.status,
      isFunded: phase.isFunded,
      startingBalance: this.challenge.balanceAmount,
      profitTarget: fields.profitTarget?.value,
      maxLoss: fields.maxLoss?.value,
      maxDailyLoss: fields.maxDailyLoss?.value,
      minTradingPeriod: fields.minTradingPeriod
        ? ` ${fields.minTradingPeriod} ${
            fields.minTradingPeriod === 1 ? 'day' : 'days'
          }`
        : 'N/A',
      tradingPeriod: fields.tradingPeriod
        ? ` ${fields.tradingPeriod} ${
            fields.tradingPeriod === 1 ? 'day' : 'days'
          }`
        : 'N/A',
      profitBroker: 1 - traderSplitPerc,
      profitTrader: traderSplitPerc,
      prizeValue: fixedTrader,
      isSplitPrize: !!fields.profitSplit,
      tradingAccount: phase.tradingAccount,
      closePnl: phase.tradingAccount?.pnl ?? 0,
      openPnl: 0,
    };
  }

  /**
   * Opens the "profit share history" modal
   * @returns {void}
   */
  openAddProfitShareHistoryPopup() {
    this.popupService.open({
      component: 'prfProfitShareHistoryPopup',
      resolve: {
        profitShareHistoryLog: () => this.historyLog,
      },
    });
  }

  streamProfitShareHistoryLog() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(
        (): rx.Observable<HistoryLogRow[]> => {
          return rx.obs
            .from(this.fetchProfitShareLog())
            .pipe(rx.catchError((e) => rx.obs.NEVER));
        },
      ),
      rx.tap((data) => {
        if (data.length > 0) {
          this.isHistoryLog = true;
          this.historyLog = data;
        }
      }),
    )(null);
  }

  async fetchProfitShareLog(): Promise<[HistoryLogRow]> {
    const data = await this.customersService().getAccountProfitShareHistory(
      this.customer.id,
      this.account.id,
    );
    return data.plain();
  }
}

export const PropPhaseStatsTableComponent = {
  template,
  controller: PropPhaseStatsTableController,
  bindings: {
    challenge: '<',
    customer: '<',
    account: '<',
  },
};
